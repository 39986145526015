import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIDropDownMenu, UIText } from 'bora-material-ui'
import { propOr, propEq, findIndex, nth } from 'ramda'
import messages from '../../../consts/messages'
import { translate } from '../../Common/Translator'

import InputField from '../../../formComponents/InputField'
import { phoneCode, phoneNumber, requiredNumber } from '../../../utils/validate'
import { RequiredLabel } from '../../../formComponents/uiComponents'
import { olderBrowser } from '../../../utils/browserUtils'
import { getMaritimeStyle } from '../../../utils/maritimeStyleUtils'
import moduleStyles from '../../Modal/Modals.module.scss'
import cn from 'classnames'

const xsStylesWrap = (isDisabled) => ({
  margin: isDisabled ? getMaritimeStyle('0 0 27px', '0 0 20px') : getMaritimeStyle('0 0 25px', '0 0 8px'),
})

const mainStyle = (isHiddenOrDisabled) => {
  const otherStyles = isHiddenOrDisabled
    ? {
        boxShadow: 'none',
        height: 'auto',
        backgroundColor: 'transparent',
      }
    : {
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 11px 6px 0px, rgba(0, 0, 0, 0.02) 0px 6px 4px 0px',
        height: '44px',
        backgroundColor: 'white',
      }

  return {
    ...otherStyles,
    maxWidth: '500px',
    borderRadius: '1px',
    sm: { maxWidth: 'none' },
  }
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText
        size={getMaritimeStyle('18px', '16px')}
        color={getMaritimeStyle('#2D3955', '#000')}
        text={props.title || props.value.title}
      />
    </UILayout>
  )
}

CompanyItemTemplate.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
}

CompanyItemTemplate.defaultProps = {
  value: {},
  title: '',
}

const LabelValue = (
  {
    label,
    text,
    fieldValue,
    isDisabled,
    required,
    type,
    validate,
    allowedCountries,
    onCountrySelect,
    translateLabel,
    ...props
  },
  context
) => {
  const { intl } = context
  const { locale = 'en' } = intl

  const styles = {
    dropDownStyles: {
      width: getMaritimeStyle('100%', '500px'),
      maxWidth: '100%',
      margin: '0',
      padding: '0',
    },
    wrapperLabelStyles: {
      width: 'auto',
      center: true,
      display: 'flex',
      align: 'start',
      position: 'relative',
      sm: { padding: '0 0 6px 10px' },
    },
    labelStyles: {
      width: '180px',
      size: '15px',
      fontWeight: 'normal',
      color: getMaritimeStyle('#9DA7BF', '#94a8b2'),
      weight: 'normal',
      align: 'end',
      sm: { align: 'left', width: 'auto', margin: '0 8px 0 0' },
    },
    errorStyles: {
      color:
        window.brandProps.muiTheme.palette && window.brandProps.muiTheme.palette.error
          ? window.brandProps.muiTheme.palette.error
          : '#f44336',
      font: 'GinesoNormDem, Fjalla One',
      marginTop: '4px',
      fontSize: '12px',
      textAlign: locale === 'ar' ? 'right' : 'left',
    },
    starStyles: {
      position: 'absolute',
      size: '14px',
      fontStyle: 'italic',
      color: '#d0021b',
      sm: { position: 'static' },
    },
    wrapperInputStyles: {
      height: 'auto',
      bgColor: 'transparent',
      boxShadow: '0',
      padding: '0',
      position: 'static',
      style: {
        marginInlineEnd: '1rem',
      },
      plusColor: '#000000',
    },
    wrapperStyles: {
      position: 'relative',
      padding: '0',
      column: false,
      sm: { column: 'true' },
      style: {
        columnGap: '1rem',
      },
    },
    inputStyle: {
      background: 'transparent',
      color: '#000000',
    },
  }

  const isHiddenOrDisabled = type === 'hidden' || isDisabled

  const labelStyles = { ...styles.labelStyles, ...props.labelStyles }

  if (label === 'country') {
    const getCountryIndexByValue = findIndex(propEq('code', fieldValue))
    const countryIndex = getCountryIndexByValue(allowedCountries)

    let thisCountryName = ''

    if (countryIndex >= 0) {
      const currentCountry = nth(countryIndex)(allowedCountries)
      thisCountryName = propOr('', 'title')(currentCountry)
    }
    return (
      <UILayout
        className={moduleStyles.withColumnGap}
        data-testid="country-field"
        margin={getMaritimeStyle('0 0 27px', '0 0 20px')}
        center
        sm={{ ...xsStylesWrap(isDisabled), 'flex-start': true, column: true }}
      >
        <UILayout {...styles.wrapperLabelStyles}>
          <UIText {...labelStyles}>{intl.formatMessage(messages.country)}</UIText>
          {!isDisabled && <span className={cn('star', moduleStyles.star)}>*</span>}
        </UILayout>
        {!isDisabled && (
          <UILayout width="500px" xs={{ width: '100%' }}>
            <UIDropDownMenu
              locale={locale}
              itemsArr={allowedCountries}
              itemTemplate={CompanyItemTemplate}
              selectionRenderer={CompanyItemTemplate}
              initValue={countryIndex}
              select={(value) => {
                onCountrySelect(value.code)
              }}
              styles={styles.dropDownStyles}
            />
          </UILayout>
        )}
        {isDisabled && (
          <UILayout width="500px" style={{ paddingInline: '14px' }}>
            <UIText
              font={getMaritimeStyle('ADPortsGroup, sans-serif', 'Roboto')}
              color={getMaritimeStyle('#2D3955', '#000')}
              size={getMaritimeStyle('18px', '16px')}
              cursor="not-allowed"
            >
              {thisCountryName}
            </UIText>
          </UILayout>
        )}
      </UILayout>
    )
  }

  if (label === 'phone') {
    const phoneWrapperLabelStyles = { ...styles.wrapperLabelStyles, padding: '0', sm: { padding: 0 } }
    const phoneWrapStylesXs = { ...xsStylesWrap(isDisabled), column: true, 'flex-start': true }
    const phoneLabelStyles = { ...styles.wrapperLabelStyles, sm: { padding: 0 } }
    const phoneLabelStylesXs = { ...labelStyles.sm, padding: '0 0 3px 10px' }
    const phoneCodeStyles = { ...mainStyle(isHiddenOrDisabled) }
    const phoneCodeWrapperInputStyles = {
      ...styles.wrapperInputStyles,
      width: getMaritimeStyle('75px', '70px'),
      ...(olderBrowser && { width: '100px' }),
    }
    const phoneCodeWrapperStyles = { ...styles.wrapperStyles, width: 'auto', style: { marginInlineStart: '14px' } }
    const phoneNumberWrapperStyles = { ...styles.wrapperStyles, maxWidth: '100%', style: { columnGap: '0' } }
    const phoneCodeInputStyles = { ...styles.inputStyle }

    const [intCode, telephone] = fieldValue.split(' ')

    if (isHiddenOrDisabled) {
      phoneCodeStyles.width = '40px'
      phoneCodeInputStyles.padding = '0'
      phoneCodeWrapperInputStyles.margin = '0'
      phoneWrapperLabelStyles.padding = '0'
    }

    return (
      <UILayout margin={getMaritimeStyle('0 0 27px', '0 0 20px')} align="stretch" sm={phoneWrapStylesXs}>
        <RequiredLabel
          text={text || translate(messages.phone)}
          required={required}
          absoluteEndStar
          hideLabel={false}
          hideStar={isDisabled}
          invalid={false}
          wrapperLabelStyles={phoneLabelStyles}
          starStyles={styles.starStyles}
          labelStyles={{
            ...labelStyles,
            sm: phoneLabelStylesXs,
          }}
        />
        <UILayout
          data-testid={isDisabled ? 'phone-number-static' : 'phone-number-editable'}
          maxWidth="530px"
          className={moduleStyles.phoneNumberLayout}
        >
          {!isHiddenOrDisabled && (
            <InputField
              validate={[phoneCode]}
              className={moduleStyles.code}
              height="auto"
              name="code"
              data-testid={`${isDisabled ? 'value-profile-phoneCode' : 'field-profile-phoneCode'}`}
              type={type}
              showPlus
              hideLabel
              hideStar
              hintText=""
              wrapperInputStyles={phoneCodeWrapperInputStyles}
              wrapperStyles={phoneCodeWrapperStyles}
              style={phoneCodeStyles}
              wrapperLabelStyles={phoneWrapperLabelStyles}
              disabled={isDisabled}
              required={required}
              errorStyles={styles.errorStyles}
              starStyles={styles.starStyles}
              inputStyle={phoneCodeInputStyles}
              translateLabel={translateLabel}
            />
          )}
          {!isHiddenOrDisabled && (
            <InputField
              display-if={!isHiddenOrDisabled}
              validate={[requiredNumber, phoneNumber]}
              height="auto"
              name="phoneNumber"
              className={moduleStyles.number}
              data-testid={`${isDisabled ? 'value-profile-phoneNumber' : 'field-profile-phoneNumber'}`}
              hideLabel
              hideStar
              type={type}
              hintText=""
              wrapperInputStyles={styles.wrapperInputStyles}
              wrapperStyles={phoneNumberWrapperStyles}
              style={mainStyle(isHiddenOrDisabled)}
              wrapperLabelStyles={phoneWrapperLabelStyles}
              labelStyles={labelStyles}
              disabled={isDisabled}
              required={required}
              errorStyles={styles.errorStyles}
              starStyles={styles.starStyles}
              inputStyle={phoneCodeInputStyles}
            />
          )}
          {isHiddenOrDisabled && (
            <Fragment>
              <UIText color="#000000" size="18px" margin="0 0 0 20px" text="+&nbsp;" />
              <span data-testid="phone-int-code">
                <UIText color="#000000" size="18px" margin="0 10px 0 0" text={intCode} />
              </span>
              <span data-testid="phone-telephone">
                <UIText color="#000000" size="18px" margin="0 20px 0 0" text={telephone} />
              </span>
            </Fragment>
          )}
        </UILayout>
      </UILayout>
    )
  }

  const extraStyles = {}
  if (props.id === 'profile-phoneNr') {
    const { locale = 'en' } = intl
    extraStyles.direction = 'ltr'
    extraStyles.textAlign = locale === 'ar' ? 'end' : 'start'
  }

  return (
    <UILayout
      margin={getMaritimeStyle('0 0 27px', '0 0 20px')}
      data-testid={`${isDisabled ? `value-${props.id}` : `field-${props.id}`}`}
      flex-end
      sm={xsStylesWrap(isDisabled)}
    >
      <InputField
        validate={validate}
        height="auto"
        name={label}
        text={text}
        type={type}
        hintText=""
        wrapperInputStyles={styles.wrapperInputStyles}
        wrapperStyles={styles.wrapperStyles}
        style={mainStyle(isHiddenOrDisabled)}
        wrapperLabelStyles={styles.wrapperLabelStyles}
        labelStyles={labelStyles}
        disabled={isDisabled}
        required={required}
        errorStyles={styles.errorStyles}
        starStyles={styles.starStyles}
        absoluteEndStar
        value={fieldValue}
        inputStyle={{ ...styles.inputStyle, ...extraStyles }}
        hideStar={isDisabled}
        translateLabel={translateLabel}
      />
    </UILayout>
  )
}

LabelValue.contextTypes = {
  intl: PropTypes.any,
}

LabelValue.defaultProps = {
  required: false,
  type: 'text',
  labelStyles: {},
  text: '',
  validate: [],
}

export default LabelValue
